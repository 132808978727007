<template>
	<v-sheet flat class="mt-12">
		<v-form v-if="!sent">
			<render-content id="scregister"> </render-content>
			<v-text-field
				:label="$t('contact_form.email')"
				v-model="email"
			></v-text-field>
			<v-text-field label="Name" v-model="name"></v-text-field>
			<v-text-field label="Organisation" v-model="organisation"></v-text-field>
			<p class="text-right">
				<v-btn color="primary" @click="submit">{{
					$t("contact_form.send")
				}}</v-btn>
			</p>
		</v-form>
		<render-content v-else id="scregisterthanks"> </render-content>
	</v-sheet>
</template>

<script>
	import RenderContent from "@c/ui/RenderContent.vue";

	export default {
		name: "ContactForm",
		data: () => ({
			sent: false,
			email: "",
			name: "",
			organisation: ""
		}),
		components: {
			RenderContent
		},
		methods: {
			submit() {
				const self = this;
				self.$store
					.dispatch("emails/registrationForm", {
						email: self.email,
						name: self.name,
						organisation: self.organisation
					})
					.then(() => {
						self.sent = true;
					});
			}
		}
	};
</script>
