<template>
    <v-sheet>
        <registration-form> </registration-form>
    </v-sheet>
</template>
<script>
import RegistrationForm from '@c/ui/RegistrationForm.vue'
    export default {
        name: "Registration",
        components:{ 
            RegistrationForm
        }
    }
</script>